import React from 'react';
import { Link } from 'react-router-dom';

function NavBar() {
    return (
        <nav className='bg-white p-4'>
            <ul className="flex justify-center items-center space-x-20">
                <li><Link to="/" className="text-blue-500 hover:text-blue-700">Home</Link></li>
                <li><Link to="/photos" className="text-blue-500 hover:text-blue-700">Photos</Link></li>
            </ul>
        </nav>
    );
}

export default NavBar;
