// PhotoGallery.js
import React, { useState } from 'react';
import img1 from '../assets/gallery/2999.jpg';
import img2 from '../assets/gallery/DSCN0055.jpg';
import img3 from '../assets/gallery/family on bike.jpg';
import img4 from '../assets/gallery/felix2.jpg';
import img5 from '../assets/gallery/felix-contact.jpg';
import img6 from '../assets/gallery/ferrari.jpg';
import img7 from '../assets/gallery/GU7H2734.jpg';
import img8 from '../assets/gallery/Helge_002b.jpg';
import img9 from '../assets/gallery/IMG_3508.jfif';
import img10 from '../assets/gallery/louis.jpg';
import img11 from '../assets/gallery/Mike Kobe.png';
import img12 from '../assets/gallery/Pops  Mike London Summer 1983d1.jpg';
import img13 from '../assets/gallery/purenandkids.jpg';
import img14 from '../assets/gallery/thomcarolouis.jpg';
import img15 from '../assets/gallery/tomashiya.jpg';
import img16 from '../assets/gallery/TopGun.jpg';
import img17 from '../assets/gallery/yeyefelix.jpg';

const images = [
    { id: 1, src: img1, alt: 'Description of photo 1' },
    { id: 2, src: img2, alt: 'Description of photo 2' },
    { id: 3, src: img3, alt: 'Description of photo 3' },
    { id: 4, src: img4, alt: 'Description of photo 4' },
    { id: 5, src: img5, alt: 'Description of photo 5' },
    { id: 6, src: img6, alt: 'Description of photo 6' },
    { id: 7, src: img7, alt: 'Description of photo 7' },
    { id: 8, src: img8, alt: 'Description of photo 8' },
    { id: 9, src: img9, alt: 'Description of photo 9' },
    { id: 10, src: img10, alt: 'Description of photo 10' },
    { id: 11, src: img11, alt: 'Description of photo 11' },
    { id: 12, src: img12, alt: 'Description of photo 12' },
    { id: 13, src: img13, alt: 'Description of photo 13' },
    { id: 14, src: img14, alt: 'Description of photo 14' },
    { id: 15, src: img15, alt: 'Description of photo 15' },
    { id: 16, src: img16, alt: 'Description of photo 16' },
    { id: 17, src: img17, alt: 'Description of photo 17' },
];

function PhotoGallery() {
    const [selectedImg, setSelectedImg] = useState(null);

    // Inline style for the masonry grid
    const masonryStyle = {
        columnCount: 3,
        columnGap: '1em'
    };

    // Responsive styles
    const responsiveStyle = `
        @media (max-width: 640px) {
            .masonry {
                column-count: 1;
            }
        }
        @media (min-width: 641px) and (max-width: 768px) {
            .masonry {
                column-count: 2;
            }
        }
        @media (min-width: 769px) {
            .masonry {
                column-count: 3;
            }
        }
        @media (min-width: 1024px) {
            .masonry {
                column-count: 4;
            }
        }
        @media (min-width: 1280px) {
            .masonry {
                column-count: 5;
            }
        }
    `;

    return (
        <div className="container mx-auto px-4">
            <style>{responsiveStyle}</style>
            <div className="masonry" style={masonryStyle}>
                {images.map((image) => (
                    <img
                        key={image.id}
                        src={image.src}
                        alt={image.alt}
                        className="cursor-pointer rounded shadow mb-4 w-full h-auto"
                        onClick={() => setSelectedImg(image)}
                    />
                ))}
            </div>
            {selectedImg && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4">
                    <div className="bg-white p-2 rounded shadow-lg overflow-auto max-h-full">
                        <img src={selectedImg.src} alt={selectedImg.alt} className="max-w-full max-h-[80vh] h-auto" />
                        <button onClick={() => setSelectedImg(null)} className="absolute top-0 right-0 p-2 text-white bg-red-500 hover:bg-red-700 rounded">
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PhotoGallery;