// PhotosPage.js
import React from 'react';
import PhotoGallery from '../components/PhotoGallery';

function PhotosPage() {
  return (
    <div>
      <h1 className="text-3xl font-bold text-center my-8">Photo Gallery</h1>
      <PhotoGallery />
    </div>
  );
}

export default PhotosPage;