import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ContactsPage from './pages/ContactsPage';
import PhotosPage from './pages/PhotosPage';
import NavBar from './components/NavBar';
import Memorial from './pages/MemorialPage';

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/memorial" element={<Memorial />} />
        <Route path="/contacts" element={<ContactsPage />} />
        <Route path="/photos" element={<PhotosPage />} />
        <Route path="/" element={<HomePage />} />
      </Routes>
    </Router>
  );
}

export default App;
