// MemorialPage.js
import React from 'react';

function MemorialPage() {
    return (
        <div>
            <h1 className="text-3xl font-bold text-center my-8">Memorial Page</h1>
            <p className="text-center">This is the Memorial Page</p>
        </div>
    );
}

export default MemorialPage;