// Homepage.js
import React from 'react';
import Helge_Stavonhagen from '../assets/Yeye main.jpg';
import Yeye_and_felix from '../assets/gallery/yeyefelix.jpg'

function HomePage() {
    return (
        <div className="container mx-auto px-4">
            <header className="my-12">
                <h1 className="text-4xl font-bold text-center text-gray-800">Welcome to the Stavonhagen family website</h1>
                <p className="text-xl mt-4 text-center text-gray-600">
                    Founded by Helge Stavonhagen.
                </p>
            </header>
            <div className="mt-8 flex justify-center">
                <img src={Helge_Stavonhagen} alt="Helge Stavonhagen" className="rounded-lg" style={{ width: '25%', height: 'auto' }} />
            </div>
            <section className="my-10">
                <h2 className="text-3xl text-center font-semibold text-gray-700">The Story</h2>
                <p className="mt-2 ml-10 mr-10 text-center text-gray-500">
                    This is the Stavonhagen family website, the domain was registered on Saturday 25th of April 1998 by Helge Stavonhagen.<br></br> The website was created to keep the family connected and to share family news and photos.<br></br>
                    He created the first version of the website that same year and kept it up to date until his passing on the 27th of march 2024.<br></br>
                    The website is now maintained by his son and grandson, Thomas and Felix.
                </p>
                <div className="mt-8 flex justify-center">
                <img src={Yeye_and_felix} alt="Helge and Felix" className="rounded-lg" style={{ width: '15%', height: 'auto' }} />
                </div>
            </section>
            {/* footer saying the website is still under construction */}
            <footer className="my-12">
                <p className="text-center text-gray-500">This website is still under construction</p>
            </footer>
        </div>
    );
}

export default HomePage;
